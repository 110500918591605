import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { avatar } from '../../assets';
import { AvatarListInline, AvatarListInline2, UserBar } from "../../components";

export default function Teams() {
    return (
        <Container fluid className="pt-4 pb-4">
            <Row>
                <Col lg={8} md={7} sm={12}>
                    <h4 className="display-6">
                        <span className="text-muted">Good Morning,</span> John Doe
                    </h4>
                    <p className="text-muted">Team Online</p>
                    <AvatarListInline />
                    <div className="shadow rounded p-2">
                        <h6 className="mt-2 mb-3 fw-bold">Top Employees</h6>
                        <div className="table-responsive">
                            <table className="table table-borderless table-hover" width="100%" style={{ minWidth: '500px' }}>
                                <tbody>
                                    {
                                        Array(4).fill('').map((_, _i) => {
                                            return (
                                                <tr key={_i}>
                                                    <td valign="middle">
                                                        <img
                                                            src={avatar}
                                                            width="35px"
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                        <span className="ms-2">john doe</span>
                                                    </td>
                                                    <td valign="middle">Backend Development</td>
                                                    <td valign="middle">{_i + Math.ceil(Math.random() * 10)} Tasks</td>
                                                    <td valign="middle"><span className="text-muted">{_i} Leaves</span></td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Col>
                <Col lg={4} md={5} sm={12}>
                    <div className="border rounded p-2">
                        <p className="fw-bold small mt-3">UPCOMING Submissions</p>
                        {Array(5).fill('').map((_, _i) => {
                            return <UserBar key={_i} />
                        })}
                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col xs={6}>
                    <button type="button" className="btn btn-primary text-light mb-3">
                        <i className="fa fa-plus me-2"></i>
                        <span>Assign New Tasks</span>
                    </button>
                </Col>
                <Col xs={6} className="text-end">
                    <button type="button" className="btn btn-danger text-light mb-3">
                        <i className="fa fa-trash me-2"></i>
                        <span>Delete Tasks</span>
                    </button>
                </Col>
                <Col xs={12}>
                    <div className="shadow rounded">
                        <div
                            className="p-2 d-flex flex-wrap align-items-center justify-content-between"
                        >
                            <h5 className="mb-0 mt-2">Team Activities</h5>
                            <div className="text-end">
                                <div className="dropdown">
                                    <button className="bg-transparent btn px-1 py-0 border-0 mx-1 align-self-start focus_dark" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <FontAwesomeIcon icon={faEllipsis} className="display-6" />
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table mb-0" width="100%">
                                <thead>
                                    <tr>
                                        <th className="border-bottom py-3">
                                            <input type="checkbox" name="" id="" />
                                        </th>
                                        <th className="border-bottom py-3">Project</th>
                                        <th className="border-bottom py-3">Tag</th>
                                        <th className="border-bottom py-3">Deadline</th>
                                        <th className="border-bottom py-3">Team</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="py-3">
                                            <input type="checkbox" name="" id="" />
                                        </td>
                                        <td className="py-3">App Design</td>
                                        <td className="py-3">
                                            <div
                                                className="rounded d-inline px-2 py-1"
                                                style={{ backgroundColor: 'rgba(243, 157, 0, 0.1)' }}
                                            >
                                                UI/UX
                                            </div>
                                        </td>
                                        <td className="py-3">11 Apr 2022</td>
                                        <td className="py-3">
                                            <AvatarListInline2 />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="py-3">
                                            <input type="checkbox" name="" id="" />
                                        </td>
                                        <td className="py-3">App Development</td>
                                        <td className="py-3">
                                            <div
                                                className="rounded d-inline px-2 py-1"
                                                style={{ backgroundColor: 'rgba(232, 222, 0, 0.1)' }}
                                            >
                                                App Development
                                            </div>
                                        </td>
                                        <td className="py-3">11 Apr 2022</td>
                                        <td className="py-3">
                                            <AvatarListInline2 />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}