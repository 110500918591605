import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { avatar } from '../../assets';
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";

export default function UserBar() {
    return (
        <div
            className="card p-2 d-flex flex-row mb-1"
            style={{ borderRadius: '15px' }}
        >
            <div
                className="d-flex p-1 align-items-center flex-grow-1 justify-content-between"
            >
                <div className="d-flex align-items-center">
                    <div className="me-2">
                        <img
                            src={avatar}
                            width="35px"
                            alt=""
                            className="img-fluid"
                        />
                    </div>
                    <div>
                        <p className="m-0" style={{ fontSize: '0.75rem' }}>
                            <small>Lorem Ipsum</small>
                            <br />
                            <small>Web Design</small>
                        </p>
                    </div>
                </div>
                <div>
                    <small className="text-muted">{moment().format('L')}</small>
                </div>
            </div>
            <div className="dropdown">
                <button className="bg-transparent btn px-1 py-0 border-0 mx-1 align-self-start focus_dark" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <FontAwesomeIcon icon={faEllipsis} />
                </button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li><a className="dropdown-item" href="#">Action</a></li>
                    <li><a className="dropdown-item" href="#">Another action</a></li>
                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                </ul>
            </div>
        </div>
    );
}