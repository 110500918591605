import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function DashboardCard(props) {
    return (
        <div
            className="card p-2 d-flex flex-row mb-1"
            style={{ borderRadius: '15px' }}
        >
            <div
                className="d-flex p-1 align-items-center flex-grow-1 justify-content-between"
            >
                <div className="d-flex align-items-center">
                    <div>
                        <FontAwesomeIcon icon={props.icon} width="35px" />
                    </div>
                    <div>
                        <p className="m-0" style={{ fontSize: '1rem' }}>
                            {props.title}
                        </p>
                    </div>
                </div>

                <div>
                    <small className="text-muted">{props.values}</small>
                </div>
            </div>
        </div>
    );
}