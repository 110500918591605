import { faAdd, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Modal, Row, Spinner } from 'react-bootstrap'
import API from '../../axios';
import { MyButton, UsePagination } from '../../components';
import PageTitle from '../../hooks/page-title'
import AirportForm from './airport-form';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const Airports = () => {
    PageTitle("Airports");
    const [isLoading, setIsLoading] = useState(false);
    const [airports, setAirports] = useState([]);
    const [page, setPage] = useState(1)

    const [singleRecord, setSingleRecord] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [deleteAirport, setDeleteAirport] = useState(false);

    const [id, setId] = useState('');
    const toggleForm = () => setShowForm(!showForm);

    useEffect(() => {
        getAirportsData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const getAirportsData = async () => {
        setIsLoading(true);
        try {
            let data = await API('post', `admin/get-airports-admin?page=${page}`, '')
            setAirports(data?.airports);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    const updateRecord = (item) => {
        setSingleRecord(item);
        setShowForm(true);
    }

    const handleDelete = async () => {
        // setIsLoading(true);
        // try {
        //     await API("method", `api++++++${id}`);
        //     setIsLoading(false);
        //     getAirportsData();
        //     toast.success("Airport delete successfully", {
        //         position: toast.POSITION.BOTTOM_LEFT,
        //     });
        // } catch (error) {
        //     if (error?.response?.status === 400) {
        //         toast.error(error.response.data.error, {
        //             position: toast.POSITION.BOTTOM_LEFT,
        //         });
        //         setIsLoading(false);
        //     } else {
        //         toast.error("Error occurred. Please try again", {
        //             position: toast.POSITION.BOTTOM_LEFT,
        //         });
        //         setIsLoading(false);
        //     }
        // }
    };

    const handleClose = () => setDeleteAirport(false);

    const addRecord = () => {
        if (!!singleRecord) {
            setSingleRecord(null);
        }
        toggleForm();
    };

    return (
        <Container fluid>
            <Row className="mt-4">
                {showForm && (
                    <AirportForm
                        item={singleRecord}
                        afterSubmit={() => {
                            getAirportsData();
                            toggleForm();
                        }}
                        closeForm={() => addRecord()}
                    />
                )}

                {!showForm && (
                    <>
                        <Col sm={6}>
                        </Col>
                        <Col sm={6} className="d-flex justify-content-end mb-4">
                            <MyButton
                                label='Add'
                                onClick={updateRecord}
                                icon={faAdd}
                                reverse='true'
                            />
                        </Col>
                        <Col xs={12}>
                            <div className="shadow rounded">
                                <div className="table-responsive">
                                    <table className="table" width="100%">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th className="border-bottom py-3">Airport name</th>
                                                <th className="border-bottom py-3 d-none d-lg-table-cell">City name</th>
                                                <th className="border-bottom py-3 d-none d-md-table-cell">City code</th>
                                                <th className="border-bottom py-3 d-none d-sm-table-cell">Status</th>
                                                <th className="border-bottom py-3">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                isLoading ? (
                                                    <tr>
                                                        <td colSpan="6" className="text-center py-3">
                                                            <Spinner animation="border" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                        </td>
                                                    </tr>
                                                ) : !airports?.data?.length ? (
                                                    <tr>
                                                        <td colSpan="6" className="text-center text-muted">
                                                            No data found!
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    airports?.data?.map((_v, _i) => (
                                                        <tr key={_i}>
                                                            <td></td>
                                                            <td valign="middle">{_v?.ap_name ? _v?.ap_name : "-"}</td>
                                                            <td valign="middle" className='d-none d-lg-table-cell'>{_v?.ap_city_name ? _v?.ap_city_name : "-"}</td>
                                                            <td valign="middle" className='d-none d-md-table-cell'>{_v?.ap_code ? _v?.ap_code : "-"}</td>
                                                            <td valign="middle" className='d-none d-sm-table-cell'>
                                                                <div className="form-check form-switch">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        role="switch"
                                                                        // checked={_v?.active ? true : false}
                                                                        defaultChecked={_v?.active ? true : false}
                                                                    // onChange={() => { }}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td valign='middle'>
                                                                <div className='d-flex align-item-center flex-row gap-3'>
                                                                    <FontAwesomeIcon
                                                                        icon={faPen}
                                                                        className='fa-sm text-primary'
                                                                        onClick={() => {
                                                                            updateRecord(_v);
                                                                        }}
                                                                    />
                                                                    <FontAwesomeIcon
                                                                        icon={faTrash}
                                                                        className='fa-sm text-danger'
                                                                        onClick={() => {
                                                                            setId(_v?.airport_id);
                                                                            setDeleteAirport(true);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {airports?.total > airports?.per_page &&
                                <div className='mt-4 d-flex justify-content-end'>
                                    <UsePagination
                                        setPage={setPage}
                                        totalItems={airports?.total}
                                        perPage={airports?.per_page}
                                    />
                                </div>
                            }
                        </Col>
                    </>
                )}
                {/* Delete Single Item */}
                <Modal show={deleteAirport} onHide={handleClose} centered>
                    <div className="text-end">
                        <Button
                            variant="light"
                            onClick={handleClose}
                            className="rounded-circle"
                            children="&times;"
                        />
                    </div>
                    <Modal.Body>
                        <p className="fs-5 text-center">Are you sure</p>
                        <div className="d-flex justify-content-center align-items-center gap-2 px-2 pb-2">
                            <Button variant="secondary" onClick={handleClose}>
                                No
                            </Button>
                            <Button variant="danger" onClick={() => {
                                handleDelete();
                                handleClose();
                            }}>
                                Yes
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>

            </Row >
            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={true}
                draggable
            />
        </Container >
    )
}

export default Airports