import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { myRoutes } from './my-routes';
import { storeUser } from '../store/reducer';
import Layout from '../layout/layout';
import { Page404, SignIn } from '../screens';
import { SplashScreen } from '../components';
import API from '../axios';

export default function Router() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const _isLogged = useSelector((state) => state.storeReducer.isLogged);
    const user = useSelector((state) => state.storeReducer.user);

    React.useEffect(() => {
        getUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const getUser = async () => {
        if (user === null) {
            let token = localStorage.getItem("@ACCESS_TOKEN");
            if (token !== null) {
                try {
                    const res = await API("get", "admin/me");
                    dispatch(storeUser({ "user": res.detail.admin }));
                    setIsLoading(false);
                } catch (err) {
                    if (err?.response?.status === 401) {
                        localStorage.removeItem("@ACCESS_TOKEN");
                        return <Navigate to="/sign-in" replace />;
                    } else {
                        setIsLoading(false);
                    }
                }
            }
            setIsLoading(false)
        }
        setIsLoading(false)
    };

    return (
        <>
            {
                isLoading ?
                    <SplashScreen />
                    :
                    <>
                        <Routes>
                            <Route
                                path="/sign-in"
                                element={
                                    <CheckUser flag={_isLogged}>
                                        <SignIn />
                                    </CheckUser>
                                }
                            />
                            {myRoutes.map((_v, _i) => {
                                return (
                                    <Route
                                        key={_i}
                                        path={_v.path}
                                        exact
                                        element={
                                            <ProtectedRoute flag={_isLogged} layout={_v.layout}>
                                                {_v.element}
                                            </ProtectedRoute>
                                        }
                                    />
                                );
                            })}
                            <Route
                                path="*"
                                element={
                                    <Page404 />
                                }
                            />
                        </Routes>
                    </>
            }
        </>
    );
}

const ProtectedRoute = ({ flag, layout, children }) => {
    if (!flag) {
        return <Navigate to="/sign-in" replace />;
    }
    if (layout) {
        return (
            <Layout children={children} />
        );
    }
    return children;
};

const CheckUser = ({ flag, children }) => {
    if (flag) {
        return <Navigate to="/" replace />;
    }
    return children;
};