import React, { useState } from "react";
import { MyButton } from "../../components";
import { Container, Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt, faUserAlt } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TextField from "../../components/custom-inputs/text-field";
import PageTitle from "../../hooks/page-title";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginUser } from "../../store/reducer";

export default function SignIn() {
    PageTitle("Sign-in");

    const [isLoading, setIsLoading] = useState(false)
    const [values, setValues] = useState({
        email: "",
        password: "",
    });

    const dispatch = useDispatch();

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const api = process.env.REACT_APP_API_KEY
        try {
            let { data } = await axios.post(api + "admin/admin-login", values)
            dispatch(loginUser({ "user": data?.detail, "token": data?.token_detail?.access_token }));
            setIsLoading(false);
        } catch (error) {
            console.log("error", error);
            if (error?.response?.status === 400) {
                toast.error(error.response.data.error, {
                    position: toast.POSITION.BOTTOM_LEFT,
                });
                setIsLoading(false);
            } else {
                toast.error("Error occurred. Please try again", {
                    position: toast.POSITION.BOTTOM_LEFT,
                });
                setIsLoading(false);
            }
        }
    }

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    }

    return (
        <div className="signin_page">
            <Container fluid>
                <div className="form_wrapper shadow p-4 bg-light">
                    <Form autoComplete="off" onSubmit={handleLogin}>
                        <div className="text-center mt-5 mb-3">
                            <FontAwesomeIcon icon={faUserAlt} className='display-1' />
                        </div>
                        <h6 className="display-6 text-center mb-4">Welcome</h6>
                        <div className="mb-3">
                            <TextField
                                label='Email'
                                type="email"
                                name="email"
                                placeholder='john@example.com'
                                required
                                error=''
                                handleInput={e => handleChange(e)}
                            />
                        </div>
                        <div className="mb-5">
                            <TextField
                                label="Password"
                                type="password"
                                name="password"
                                placeholder='xxxx'
                                required
                                error=''
                                handleInput={e => handleChange(e)}
                            />
                        </div>
                        <MyButton
                            type="submit"
                            icon={faSignInAlt}
                            color="dark"
                            btnStyle="w-100 rd-25"
                            label='LogIn'
                            loading={isLoading}
                        />
                    </Form>
                </div>
                <ToastContainer
                    position="bottom-left"
                    autoClose={2000}
                    hideProgressBar={true}
                    draggable
                />
            </Container>
        </div>
    );
}