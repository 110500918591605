import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Stack, Spinner } from 'react-bootstrap'
import API from '../../axios';
import PageTitle from '../../hooks/page-title';
import moment from 'moment'
import { MyButton, TextField, UsePagination } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faHotel, faPen, faPlane, faTrash, faTruckLoading } from '@fortawesome/free-solid-svg-icons';
import { faCreativeCommons } from '@fortawesome/free-brands-svg-icons';

const FlightOrders = () => {
    PageTitle("Flight Orders")
    const [isLoading, setIsLoading] = useState(false);
    const [flightData, setFlightData] = useState([]);
    const [from, setForm] = useState('');
    const [to, setTo] = useState('');
    const [page, setPage] = useState(1)

    useEffect(() => {
        getFlightOrder()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const getFlightOrder = async (e) => {
        if (e && e.preventDefault) { e.preventDefault(); }
        let obj = {
            "from": from ? from : '',
            "to": to ? to : ''
        }
        setIsLoading(true);
        try {
            let data = await API('post', `admin/get-flight-orders?page=${page}`, obj)
            setFlightData(data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <Container fluid>
            <Row className="mt-4">
                <Col xs={12}>
                    <form onSubmit={e => getFlightOrder(e)}>
                        <div className='d-flex flex-column flex-sm-row gap-2 mb-4'>
                            <TextField type='date' handleInput={(e) => setForm(e.target.value)} />
                            <TextField type='date' handleInput={e => setTo(e.target.value)} />
                            <MyButton
                                color='primary'
                                label="Search"
                                type='submit'
                            />
                        </div>
                    </form>
                </Col>
                <Col xs={6} className="d-flex justify-content-end">
                </Col>
                <Col xs={12}>
                    <div className="shadow rounded">
                        <div className="table-responsive">
                            <table className="table mb-0" width="100%">
                                <thead>
                                    <tr>
                                        <th />
                                        <th className="border-bottom py-3">PNR</th>
                                        <th className="border-bottom py-3 d-none d-sm-table-cell">Order Number</th>
                                        <th className="border-bottom py-3 d-none d-sm-table-cell">Total amount</th>
                                        <th className="border-bottom py-3 d-none d-sm-table-cell">Order amount</th>
                                        <th className="border-bottom py-3 d-none d-sm-table-cell">Order currency</th>
                                        <th className="border-bottom py-3 d-none d-sm-table-cell">Platform share amount</th>
                                        <th className="border-bottom py-3 d-none d-md-table-cell">Created at</th>
                                        <th className="border-bottom py-3 d-none d-sm-table-cell">Status</th>
                                        <th className="border-bottom py-3">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        isLoading ? (
                                            <tr>
                                                <td colSpan="10" className="text-center">
                                                    <Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </td>
                                            </tr>
                                        ) : !flightData?.data?.length ? (
                                            <tr>
                                                <td colSpan="10" className="text-center text-muted">
                                                    No data found!
                                                </td>
                                            </tr>
                                        ) : (
                                            flightData?.data?.map((_v, _i) => (
                                                <tr key={_i}>
                                                    <td />
                                                    <td>{_v?.pnr ? _v?.pnr : "-"}</td>
                                                    <td className='d-none d-sm-table-cell'>{_v?.orderNum ? _v?.orderNum : "-"}</td>
                                                    <td className='d-none d-sm-table-cell'>{_v?.total_amount ? Math.round(_v?.total_amount) : "-"}</td>
                                                    <td className='d-none d-sm-table-cell'>{_v?.order_amount ? Math.round(_v?.order_amount) : "-"}</td>
                                                    <td className='d-none d-sm-table-cell'>{_v?.order_currency ? _v?.order_currency : "-"}</td>
                                                    <td className='d-none d-sm-table-cell'>{_v?.platform_share_amount ? _v?.platform_share_amount : "-"}</td>
                                                    <td className='d-none d-md-table-cell'>{_v?.created_at ? moment(_v?.created_at).format('MMMM Do YYYY') : "-"}</td>
                                                    <td className='text-capitalize d-none d-sm-table-cell'>
                                                        {_v?.status}
                                                        {
                                                            _v?.status === 'completed' ? <FontAwesomeIcon icon={faCheckCircle} className='fa-sm text-primary' /> :
                                                                _v?.status === 'proceed' ? <FontAwesomeIcon icon={faTruckLoading} className='fa-sm text-primary' /> :
                                                                    _v?.status === 'created' ? <FontAwesomeIcon icon={faCreativeCommons} className='fa-sm text-primary' /> :
                                                                        '-'
                                                        }
                                                    </td>
                                                    <td valign='middle'>
                                                        <div className='d-flex align-item-center flex-row gap-3'>
                                                            {_v?.refund_stripe ?
                                                                <FontAwesomeIcon icon={faPen} className='fa-sm text-primary' />
                                                                : null
                                                            }
                                                            <FontAwesomeIcon icon={faTrash} className='fa-sm text-danger' />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                    {flightData?.total > flightData?.per_page &&
                        <div className='mt-4 d-flex justify-content-end'>
                            <UsePagination
                                setPage={setPage}
                                totalItems={flightData?.total}
                                perPage={flightData?.per_page}
                            />
                        </div>
                    }
                </Col>
            </Row >
        </Container >
    )
}

export default FlightOrders