import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { DefaultProfile } from '../../assets';
import { MyButton, TextField } from "../../components";
import { faCamera, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from 'react-redux';
import API from "../../axios";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PageTitle from '../../hooks/page-title';

const Profile = () => {
    PageTitle("Profile")
    const user = useSelector((state) => state.storeReducer.user);

    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [imageBlob, setImageBlob] = useState(null);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const changeProfile = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        let fd = {
            file,
            name,
            email,
            password
        };
        try {
            let data = await API('post', 'admin/update-profile', fd);
            setIsLoading(false);
            // dispatch(storeUser({ "user": res.detail.admin }));
            toast.success('Profile Update Successfully', {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        }
        catch (error) {
            setIsLoading(false);
            toast.error(error.message, {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        }
    }

    const handleChange = async (e) => {
        setFile(e.target.files[0]);
        let blob = URL.createObjectURL(e.target.files[0])
        setImageBlob(blob)
    };

    return (
        <Container className='pt-4'>
            <Row className='mt-2'>
                <Col xs={0} md={3} />
                <Col xs={12} md={6} className="d-flex align-item-center justify-content-center flex-column">
                    <Row>
                        <Col xs={12}>
                            <h4 className="display-6 text-center">
                                Profile Information
                            </h4>
                        </Col>
                    </Row>
                    <Row className='mt-4 mb-4'>
                        <Col xs={12} >
                            <div className='text-center position-relative'>
                                <label htmlFor='btnImg' className='position-absolute top-100 start-50 translate-middle btn'>
                                    <input type='file' hidden id='btnImg' onChange={handleChange} />
                                    <FontAwesomeIcon icon={faCamera} />
                                </label>
                                <img src={imageBlob ? imageBlob : user?.profile_image_url ? user?.profile_image_url : DefaultProfile} alt="profileImage" width="170px" height="170" className='rounded-circle' />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sx={12}>
                            <form className="mb-4 mt-4 d-grid gap-4" autoComplete="off" onSubmit={changeProfile}>
                                <TextField
                                    type='text'
                                    label="Name"
                                    required
                                    placeholder="Full name"
                                    inputStyle="w-100"
                                    initValue={user?.name}
                                    handleInput={(e) => setName(e.target.value)}
                                />
                                <TextField
                                    type='email'
                                    label="Email"
                                    required
                                    placeholder="email"
                                    inputStyle="w-100"
                                    initValue={user?.email}
                                    handleInput={(e) => setEmail(e.target.value)}
                                />
                                <TextField
                                    type='password'
                                    label="password"
                                    required
                                    placeholder="password"
                                    inputStyle="w-100"
                                    handleInput={(e) => setPassword(e.target.value)}
                                />
                                <MyButton
                                    type='submit'
                                    label="Change"
                                    btnStyle="w-100"
                                    color='dark'
                                    icon={faPen}
                                    reverse={true}
                                    loading={isLoading}
                                />
                            </form>
                        </Col>
                    </Row>
                </Col>
                <Col xs={0} md={3} />
            </Row >
            <ToastContainer
                autoClose={3000}
            />
        </Container >
    )
}

export default Profile