import { Airports, Dashboard, FlightOrders, Profile, Teams, Users } from '../screens';

export const myRoutes = [
    {
        path: '/',
        element: <Dashboard />,
        protected: true,
        layout: true
    },
    {
        path: '/teams',
        element: <Teams />,
        protected: true,
        layout: true
    },
    {
        path: '/users',
        element: <Users />,
        protected: true,
        layout: true
    },
    {
        path: '/flight-orders',
        element: <FlightOrders />,
        protected: true,
        layout: true
    },
    {
        path: '/profile',
        element: <Profile />,
        protected: true,
        layout: true
    },
    {
        path: '/airports',
        element: <Airports />,
        protected: true,
        layout: true
    }
]