import { faCheckDouble, faHotel, faMoneyBill, faMoneyBillWheat, faPlane, faUsers } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import API from "../../axios";
import DashboardCard from "../../components/dashboard-card/dashboard-card";
import PageTitle from "../../hooks/page-title";

export default function Dashboard() {
    PageTitle("Dashboard")
    const user = useSelector((state) => state.storeReducer.user);

    const [isLoading, setIsLoading] = useState(false);
    const [values, setValues] = useState({});

    useEffect(() => {
        getDashboard();
    }, [])


    const getDashboard = async () => {
        setIsLoading(true);
        try {
            let data = await API("get", "admin/dashboard")
            setIsLoading(false);
            setValues(data?.detail);
        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <Container fluid className="pt-4 pb-4">
            <Row>
                <Col lg={8} md={7} sm={12}>
                    <h4 className="display-6">
                        <span className="text-muted">Good Morning,</span> {user?.name ? user?.name : "Admin"}
                    </h4>
                </Col>
            </Row>

            {isLoading && (
                <Row className="mt-4 ms-4">
                    <Col className="mt-4 ms-4">
                        <Spinner animation="border" variant="secondary" role="status" size="md">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Col>
                </Row>
            )}
            {!isLoading &&
                <Row className="p-2 mt-4 shadow rounded row-cols-1 row-cols-sm-2 row-cols-md-3 gy-1">
                    <Col>
                        <DashboardCard title="Total Customers" values={values?.total_users ? values?.total_users : '-'} icon={faUsers} />
                    </Col>
                    <Col>
                        <DashboardCard title="Total Flight Orders" values={values?.total_flight_orders ? values?.total_flight_orders : '-'} icon={faPlane} />
                    </Col>
                    <Col>
                        <DashboardCard title="Completed Orders" values={values?.total_flight_completed_orders ? values?.total_flight_completed_orders : '-'} icon={faCheckDouble} />
                    </Col>
                    <Col>
                        <DashboardCard title="Refunded Orders" values={values?.total_flight_refunded_orders ? values?.total_flight_refunded_orders : '-'} icon={faMoneyBill} />
                    </Col>
                    <Col>
                        <DashboardCard title="Total Hotel Orders" values={values?.total_hotel_orders ? values?.total_hotel_orders : '-'} icon={faHotel} />
                    </Col>
                    <Col>
                        <DashboardCard title="Orders Earnings" values={values?.total_flight_orders_earnings ? values?.total_flight_orders_earnings : '-'} icon={faMoneyBillWheat} />
                    </Col>
                </Row>
            }
        </Container>
    )
}