import React, { useEffect, useState } from 'react'
import { Container, Row, Col, DropdownButton, Dropdown, Stack, Spinner } from 'react-bootstrap'
import { faHotel, faPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import API from '../../axios';
import PageTitle from '../../hooks/page-title';
import { MyButton, UsePagination } from '../../components';

const Users = () => {
    PageTitle("Users");
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [signType, setSignType] = useState('')
    const [status, setStatus] = useState('')
    const [page, setPage] = useState(1)

    useEffect(() => {
        getAllUsers();
        setIsLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const getAllUsers = async (e) => {
        if (e && e.preventDefault) { e.preventDefault(); }
        let obj = {
            status: '',
            type: ''
        }
        if (signType) {
            obj.type = signType
        }
        if (status) {
            obj.status = status
        }
        try {
            let data = await API('post', `admin/all-users?page=${page}`, obj)
            setUsers(data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    return (
        <Container fluid>
            <Row className="mt-4" >
                <Col xs={6} >
                    <form onSubmit={e => getAllUsers(e)}>
                        <Stack direction="horizontal" gap={2} className="mb-4 text-light">
                            <DropdownButton
                                id="dropdown-basic-button"
                                title={signType === '' ? "Signup Type" : signType}
                                variant="secondary"
                                onSelect={e => setSignType(e)}
                            >
                                <Dropdown.Item eventKey="email">Email</Dropdown.Item>
                                <Dropdown.Item eventKey="google">Google</Dropdown.Item>
                                <Dropdown.Item eventKey="apple">Apple</Dropdown.Item>
                                <Dropdown.Item eventKey="facebook">Facebook</Dropdown.Item>
                            </DropdownButton>
                            <DropdownButton
                                id="dropdown-basic-button"
                                title={status === '' ? "Status" : status}
                                variant="secondary"
                                onSelect={e => setStatus(e)}
                            >
                                <Dropdown.Item eventKey="active">Active</Dropdown.Item>
                                <Dropdown.Item eventKey="not-active">Not active</Dropdown.Item>
                            </DropdownButton>
                            <MyButton
                                type='submit'
                                label='Search'
                            />
                        </Stack>
                    </form>
                </Col>
                <Col xs={6} className="d-flex justify-content-end" >
                </Col>
                <Col xs={12}>
                    <div className="shadow rounded">
                        <div className="table-responsive">
                            <table className="table mb-0" width="100%">
                                <thead>
                                    <tr>
                                        <th />
                                        <th className="border-bottom py-3">First name</th>
                                        <th className="border-bottom py-3">Last name</th>
                                        <th className="border-bottom py-3">Email</th>
                                        <th className="border-bottom py-3">Active</th>
                                        <th className="border-bottom py-3">Signup type</th>
                                        <th className="border-bottom py-3">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        isLoading ? (
                                            <tr>
                                                <td colSpan="7" className="text-center">
                                                    <Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </td>
                                            </tr>
                                        ) : !users?.data?.length ? (
                                            <tr>
                                                <td colSpan="7" className="text-center text-muted">
                                                    No data found!
                                                </td>
                                            </tr>
                                        ) : (
                                            users?.data?.map((_v, _i) => (
                                                <tr key={_i}>
                                                    <td />
                                                    <td>{_v?.first_name ? _v?.first_name : "-"}</td>
                                                    <td>{_v?.last_name ? _v?.last_name : "-"}</td>
                                                    <td>{_v?.email}</td>
                                                    <td>
                                                        <div className="form-check form-switch">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                // checked={_v?.active === true ? true : false}
                                                                defaultChecked={_v?.active ? true : false}
                                                                // onChange={() => { }}
                                                            />
                                                        </div>
                                                    </td>
                                                    < td className='text-capitalize' > {_v?.signup_type}</td>
                                                    <td valign='middle'>
                                                        <div className='d-flex align-item-center flex-row gap-3'>
                                                            <FontAwesomeIcon icon={faHotel} className='fa-sm' />
                                                            <FontAwesomeIcon icon={faPlane} className='fa-sm' />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                    {users?.total > users?.per_page &&
                        <div className='mt-4 d-flex justify-content-end'>
                            <UsePagination
                                setPage={setPage}
                                totalItems={users?.total}
                                perPage={users?.per_page}
                            />
                        </div>
                    }
                </Col>
            </Row >
        </Container >
    )
}

export default Users