import React from "react";
import { avatar } from '../../assets';

export default function AvatarListInline() {
    return (
        <ul
            className="d-flex list-unstyled align-items-center flex-wrap p-0 m-0 mb-3"
        >{
                Array(4).fill('').map((_, _i) => {
                    return (
                        <li className="me-1" key={_i}>
                            <a className="hover_avatar p-1 d-block rounded-circle" href="/#">
                                <img
                                    src={avatar}
                                    alt=""
                                    width="45px"
                                    className="img-fluid"
                                />
                            </a>
                        </li>);
                })
            }
        </ul>
    )
}