import React from "react";
import { avatar } from '../../assets';

export default function AvatarListInline2() {
    return (

        <ul
            className="float_avatar d-flex align-items-center justify-content-start p-0 m-0 list-unstyled"
        >
            {Array(4).fill('').map((_, _i) => {
                return (
                    <li key={_i} style={_i > 0 ? { marginLeft: '-15px' } : {}}>
                        <a href="#">
                            <img
                                src={avatar}
                                alt=""
                                width="35px"
                                className="img-wrapper"
                            />
                        </a>
                    </li>
                );
            })}
        </ul >
    )
}