import { faCog, faHome, faPlaneDeparture, faFileLines, faQuestionCircle, faUser, faUsers, faPlane } from "@fortawesome/free-solid-svg-icons";

export const paths = [
    [
        {
            url: '/',
            icon: faHome,
            title: 'Dashboard'
        },
        {
            url: '/users',
            icon: faUsers,
            title: 'Users'
        },
        {
            url: '/flight-orders',
            icon: faPlane,
            title: 'Flight Orders'
        },
        {
            url: '/profile',
            icon: faUser,
            title: 'Profile'
        },
        {
            url: '/airports',
            icon: faPlaneDeparture,
            title: 'Airports'
        },
        {
            url: '/teams',
            icon: faUsers,
            title: 'Team'
        },
        {
            url: '/#',
            icon: faFileLines,
            title: 'Schedule'
        }
    ]
    ,
    [
        {
            url: '/#',
            icon: faCog,
            title: 'Settings'
        },
    ]
]