import ReactPaginate from "react-paginate";

const UsePagination = ({ perPage, totalItems, setPage }) => {
  const pageCount = Math.ceil(totalItems / perPage);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  return (
    <div>
      <ReactPaginate
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="<"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default UsePagination;
