import React, { useState, useEffect } from 'react'
import { Card, Col, Container, Dropdown, DropdownButton, Form, Row, Stack } from 'react-bootstrap'
import { FileUploader } from 'react-drag-drop-files'
import { NoImg } from '../../assets';
import { MyButton, TextField } from '../../components'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const _types = ["JPG", "PNG", "GIF"];



const AirportForm = ({
    item = null,
    afterSubmit = () => { },
    closeForm,
}) => {
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [imageBlob, setImageBlob] = useState(null);

    const [formData, setFormData] = useState({
        airport_name: "",
        city_name: "",
        order: "",
        status: true,
        recommended: true,
        weekend: true,
        type: "ADD",
    });
    const changeProfile = () => { }

    useEffect(() => {
        if (!!item) {
            setFormData({
                ...formData,
                id: item.id,
                airport_name: item.airport_name,
                city_name: item.city_name,
                order: item.order,
                status: item.status,
                recommended: item.recommended,
                weekend: item.weekend,
                type: "EDIT",
                _method: "post",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (e) => {
        let { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleForm = async (e) => {
        e.preventDefault();
        console.log("Hello I am Function")
        //     setIsLoading(true);
        //     const _fd = new FormData();
        //     _fd.append("airport_name", formData.airport_name);
        //     _fd.append("city_name", formData.city_name);
        //     _fd.append("order", formData.order);
        //     _fd.append("status", formData.status === true ? "1" : "0");
        //     _fd.append("recommended", formData.recommended === true ? "1" : "0");
        //     _fd.append("weekend", formData.weekend === true ? "1" : "0");
        //     let _url =
        //         formData.type === "ADD"
        //             ? "admin/create-airport-admin"
        //             : `admin/update-single-airport-admin/${formData.id}`;
        //     if (formData.type === "EDIT") {
        //         _fd.append("_method", "post");
        //     }
        //     try {
        //         await API("post", _url, _fd);
        //         setIsLoading(false);
        //         if (formData.type === "ADD") {
        //             toast.success("New Airport Added Successfully", {
        //                 position: toast.POSITION.BOTTOM_LEFT,
        //             });
        //         } else {
        //             toast.success("Update Airport Successfully", {
        //                 position: toast.POSITION.BOTTOM_LEFT,
        //             });
        //         }
        //         afterSubmit();
        //     } catch (err) {
        //         setIsLoading(false);
        //         let errObj = {};
        //         if (err?.response?.status === 422) {
        //             for (const [key, value] of Object.entries(
        //                 err?.response?.data?.detail
        //             )) {
        //                 errObj = { ...errObj, [key]: value[0] };
        //             }
        //             setFormErrors(errObj);
        //         } else {
        //             toast.success(err?.response?.data?.message || "Error occurred. Please try again", {
        //                 position: toast.POSITION.BOTTOM_LEFT,
        //             });
        //         }
        //     }
    };

    const handleImage = (_file) => {
        setFile(_file)
        let blob = URL.createObjectURL(_file)
        setImageBlob(blob)
    };

    return (
        <Container className='pt-4'>
            <Row>
                <Col xs={0} md={4} />
                <Col xs={12} md={4} className="d-flex align-item-center justify-content-center flex-column">
                    <Col sx={12} className="mt-4 mb-4">
                        <form className="mb-4 mt-4 d-grid gap-4 justify-content-center" autoComplete="off" onSubmit={handleForm}>
                            <Stack direction='horizontal' gap={3}>
                                <TextField
                                    type='text'
                                    label="Airport Name"
                                    required
                                    name="airport_name"
                                    wrapperStyle="w-100"
                                    initValue={formData?.airport_name}
                                    handleChange={handleChange}
                                />
                                <TextField
                                    type='text'
                                    label="City name"
                                    required
                                    name="city_name"
                                    wrapperStyle="w-100"
                                    initValue={formData?.city_name}
                                    handleChange={handleChange}
                                />
                            </Stack>

                            <FileUploader
                                name="image"
                                width="100"
                                drop_area="100%"
                                types={_types}
                                multiple={false}
                                handleChange={(e) => {
                                    handleImage(e);
                                }}
                            />
                            <Card style={{ width: '100%', height: '100%', border: 'none' }}>
                                <Card.Img variant='top' style={{ width: 'auto', height: 'auto' }} src={imageBlob ? imageBlob : NoImg}></Card.Img>
                            </Card>

                            <Stack direction='horizontal' gap={3}>
                                <Form.Select aria-label="Default select example" className='w-30'>
                                    <option>Status</option>
                                    <option value="true">Active</option>
                                    <option value="false" selected={formData.status}>Not active</option>
                                </Form.Select>
                                <Form.Select aria-label="Default select example" className='w-30'>
                                    <option>Recommended</option>
                                    <option value="true">True</option>
                                    <option value="false" selected={formData.recommended}>False</option>
                                </Form.Select>
                                <Form.Select aria-label="Default select example" className='w-30'>
                                    <option>Weekend</option>
                                    <option value="true">True</option>
                                    <option value="false" selected={formData.weekend}>False</option>
                                </Form.Select>
                            </Stack>

                            <Stack direction='horizontal' gap={3}>
                                <MyButton
                                    label="Cancel"
                                    color="dark"
                                    btnStyle="w-100"
                                    onClick={closeForm}
                                />
                                <MyButton
                                    label="Add"
                                    type='submit'
                                    color="primary"
                                    loading={isLoading}
                                    btnStyle="w-100"
                                />
                            </Stack>

                        </form>
                    </Col>
                </Col>
                <Col xs={0} md={4} />
            </Row >
            <ToastContainer
                position="bottom-left"
                autoClose={2000}
                hideProgressBar={true}
                draggable
            />
        </Container >

    )
}

export default AirportForm